import React, { useContext } from "react"
import { graphql } from "gatsby"
import { BrinkContext } from "../components/context/BrinkContext"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import WidgetLoader from "../components/widgets/WidgetLoader"
import Breadcrumbs from "../components/ui/Breadcrumbs"

const CmsPage = ({ data: { sanityCmsPage }, pageContext }) => {
  const { languageCode } = useContext(BrinkContext)
  const {
    widgets,
    slug,
    pageTitle,
    pageDescription,
    hideDefaultNewsletterSignup,
    enableDarkMode
  } = sanityCmsPage
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  return (
    <LayoutComponent
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: pageDescription[languageCode] || pageDescription.en
      }}
      invertedHeader={!enableDarkMode}
      enableDarkMode={enableDarkMode}
      pageContext={pageContext}
      hideDefaultNewsletterSignup={hideDefaultNewsletterSignup}
    >
      <Breadcrumbs
        enableDarkMode={enableDarkMode}
        breadcrumbs={[
          {
            slug: `/${slug.current}`,
            name: pageTitle[languageCode] || pageTitle.en
          }
        ]}
      />
      {widgets?.length > 0 && <WidgetLoader widgets={widgets} />}
    </LayoutComponent>
  )
}

export default CmsPage

export const query = graphql`
  query ($sanityPageId: String!) {
    sanityCmsPage(_id: { eq: $sanityPageId }) {
      pageTitle {
        en
      }
      pageDescription {
        en
      }
      slug {
        current
      }
      hideDefaultNewsletterSignup
      enableDarkMode
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Image
        ...TableWidget
        ...NewsletterSignup
      }
    }
  }
`
